
import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { graphql } from "gatsby"

import PageMetaData from "components/pageMetaData"
import PostFeed from "components/postFeed"
import { postHeader } from "graphQLFragments"

import { add as addPosts } from "state/post"

import styles from "./styles.module.scss"

export const query = graphql`

	query TagPosts($tag: String! $first: Int = 5 $after: String) {

		prismic {

			posts: allPost_defaults(first: $first, after: $after tags: [$tag] sortBy: meta_firstPublicationDate_DESC where: { visibility: "visible" } ) {

				pageInfo {

			      hasNextPage

			      endCursor

			    }

				edges {

					node {

						...PostHeader

					}

				}

			}

		}

	}

`;

function TagTemplate ({

	pageContext: {

		tag

	},

	prismic,

	location,
	posts,
	pageInfo,
	loadingPosts,

	addPosts

}) {

	return (

		<div>

			<PageMetaData title={tag} url={location.href} />

			<header className={styles.header}>

				<h1>

					Posts tagged <span>{tag}</span>

				</h1>

			</header>

			<PostFeed 

				feed={posts} 
				hasMore={pageInfo.hasNextPage}
				loading={loadingPosts}
				loadMore={() => {

					addPosts({

						query,
						pageInfo,
						prismic,
						feedId: tag,

						variables: {

							tag

						}

					});

				}}

			/>

		</div>

	);

}

TagTemplate.fragments = [postHeader];

export default connect(

	(state, { pageContext: { tag }, data: { prismic: { posts: { edges, pageInfo } } } } ) => {

		const feed = state.feed.feeds[tag] || { items: [], pageInfo };

		return ({

			...feed,

			posts: [

				...edges,

				...feed.items.map(id => state.post.posts[id]).filter(v => v)

			],

			loadingPosts: state.app.loadingPosts

		});

	},

	dispatch => bindActionCreators({ addPosts }, dispatch)

)(TagTemplate);
